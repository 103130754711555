<div class="passeioja___container_body">
  <div>
    <mat-toolbar class="passeioja___toptoolbar" style="padding-right: 5px;">
      <div *ngIf="_flInstitucional" class="parallelogram faixaTertiary"></div>
      <div *ngIf="!_flInstitucional && isDesktop" class="parallelogram faixaTertiary"></div>
      <div *ngIf="isDesktop || (_usu && _usu.tpUsuario == enumTiposUsuario.Guia_1)"
        class="parallelogram faixaPrimaryGrande"></div>
      <div *ngIf="isDesktop || (_usu && _usu.tpUsuario == enumTiposUsuario.Guia_1)" class="faixaPrimaryRetGrande"></div>
      <div *ngIf="!isDesktop && _usu.tpUsuario != enumTiposUsuario.Guia_1" class="parallelogram faixaPrimaryPequena">
      </div>
      <div *ngIf="!isDesktop && _usu.tpUsuario != enumTiposUsuario.Guia_1" class="faixaPrimaryRetPequena"></div>
      <mat-toolbar style="padding-right: 5px;" class="toolbar_botoes">
        <button mat-icon-button title="Menu" (click)="sidenav.toggle()">
          <mat-icon class="iconMenu">menu</mat-icon>
        </button>
        <button mat-icon-button class="botaoLogo" title="Home" (click)='NavegarHome();' (keyup.enter)='NavegarHome()'>
          <img *ngIf="!isDesktop && !_flInstitucional" src="./assets/PasseioJaLogoTransparente.png" class="logo" media="(max-width: 560px)">
          <img *ngIf="isDesktop || _flInstitucional" src="./assets/PasseioJaLogoHorizontal.png" class="logoHoriz"
            media="(min-width: 560px)">
        </button>
        <span class="spacer"></span>
        <span class="spacer"></span>
        <button mat-flat-button class="botaoCabec" title="Menu Agência"
          *ngIf="_usu && _usu.tpUsuario == enumTiposUsuario.Guia_1 && _guia && _nmFantasiaAgencia && _arrayMenuAgencia.length > 0"
          [matMenuTriggerFor]="menuAgencia">
          <div class="boxImagem" *ngIf="isDesktop">
            <img class="logoAgencia" mat-card-avatar
              *ngIf="_guia.agenciaTurismo && _guia.agenciaTurismo.dlLogotipoAgencia"
              src="{{this._url}}/storage/{{_guia.agenciaTurismo.dlLogotipoAgencia}}" />
          </div>
          <span style="margin-top: 25px!important; font-size: 12px;">{{_nmFantasiaAgencia}}</span>
        </button>
        <button mat-flat-button class="botaoCabec"
          *ngIf="_usu && _usu.tpUsuario == enumTiposUsuario.Guia_1 && _guia && _nmFantasiaAgencia && _arrayMenuAgencia.length == 0">
          <div class="boxImagem" *ngIf="isDesktop">
            <img class="logoAgencia" mat-card-avatar
              *ngIf="_guia.agenciaTurismo && _guia.agenciaTurismo.dlLogotipoAgencia"
              src="{{this._url}}/storage/{{_guia.agenciaTurismo.dlLogotipoAgencia}}" />
          </div>
          <span style="margin-top: 25px!important; font-size: 12px;">{{_nmFantasiaAgencia}}</span>
        </button>
        <button mat-flat-button class="botaoCabec"
          *ngIf="_usu && _usu.tpUsuario == enumTiposUsuario.Guia_1 && _nmUsuario" [matMenuTriggerFor]="menuPerfil"
          title="Perfil">
          <div class="boxImagem" *ngIf="isDesktop">
            <img class="fotoUsu" mat-card-avatar *ngIf="_usu.dlFotoUsuario"
              src="{{this._url}}/storage/{{_usu.dlFotoUsuario}}" />
          </div>
          <span style="padding-top: 20px; font-size: 12px;">{{_nmUsuario}}</span>
        </button>
        <button tabindex="0" mat-button *ngIf="_flInstitucional && isDesktop" class="botaoInstitucional botaoOpcional"
          (click)="AbrirOutraAba(_urlPasseioJa);" (keyup.enter)="AbrirOutraAba(_urlPasseioJa);">Passeios
        </button>
        <button mat-button *ngIf="_flInstitucional && isDesktop" class="botaoInstitucional"
          (click)='PosicionarTag("about");' (keyup.enter)='PosicionarTag("about")'>Serviços
        </button>
        <button mat-button *ngIf="_flInstitucional && isDesktop" class="botaoInstitucional"
          (click)='PosicionarTag("vantagens");' (keyup.enter)='PosicionarTag("vantagens")'>Vantagens
        </button>
        <button mat-button *ngIf="_flInstitucional && isDesktop" class="botaoInstitucional botaoOpcional"
          (click)='PosicionarTag("important");' (keyup.enter)='PosicionarTag("important")'>Tarifas
        </button>
        <button mat-button *ngIf="_flInstitucional && isDesktop" class="botaoInstitucional botaoOpcional"
          (click)='PosicionarTag("faq");' (keyup.enter)='PosicionarTag("faq")' style="width: 70px !important;">FAQ
        </button>
        <button mat-button *ngIf="_flInstitucional && isDesktop" color="primary" class="botaoInstitucional"
          (click)='PosicionarTag("contato");' (keyup.enter)='PosicionarTag("contato")'>Contato
        </button>
        <span class="spacer"></span>
        <button mat-raised-button color="primary" *ngIf="_flInstitucional && isDesktop"
          class="botaoInstitucional botaoLogin" (click)="ChamarRotaPrincipal('/AmbosGeral/Login')"
          (keyup.enter)="ChamarRotaPrincipal('/AmbosGeral/Login')">Entrar
        </button>
        <button mat-raised-button color="accent" *ngIf="_flInstitucional && isDesktop"
          class="botaoInstitucional botaoCadastrese" (click)="ChamarRotaPrincipal('/AmbosCad/CadastroUsuario/new')"
          (keyup.enter)="ChamarRotaPrincipal('/AmbosCad/CadastroUsuario/new')">Cadastre-se
        </button>
        <button mat-button class="botaoAjuda" [matMenuTriggerFor]="menuAjuda"
          *ngIf="!_flInstitucional && _arrayMenuAjuda.length > 0">
          <div class="boxIcone">
            <mat-icon [ngStyle]="{'color':'white'}" title="Ajuda">help_outline</mat-icon>
          </div>
        </button>
        <mat-menu #menuAgencia="matMenu">
          <button mat-menu-item *ngFor="let item of _arrayMenuAgencia" (click)="ChamarRotaPrincipal(item.url)"
            (keyup.enter)="ChamarRotaPrincipal(item.url)">
            <mat-icon>{{item.icon}}</mat-icon>
            <span>{{item.label}}</span>
          </button>
        </mat-menu>
        <mat-menu #menuPerfil="matMenu">
          <button mat-menu-item *ngFor="let item of _arrayMenuPerfil" (click)="ChamarRotaPrincipal(item.url)"
            (keyup.enter)="ChamarRotaPrincipal(item.url)">
            <mat-icon>{{item.icon}}</mat-icon>
            <span>{{item.label}}</span>
          </button>
        </mat-menu>
        <mat-menu #menuAjuda="matMenu">
          <button mat-menu-item *ngFor="let item of _arrayMenuAjuda" (click)="ChamarRotaPrincipal(item.url)"
            (keyup.enter)="ChamarRotaPrincipal(item.url)">
            <mat-icon>{{item.icon}}</mat-icon>
            <span>{{item.label}}</span>
          </button>
        </mat-menu>
      </mat-toolbar>
    </mat-toolbar>
  </div>
  <mat-sidenav-container class="passeioja___side-nav-container">
    <mat-sidenav #sidenav mode="over">
      <mat-nav-list>
        <div *ngFor="let item of menu">
          <h3 mat-subheader style='height: 30px; padding-top: 15px; padding-bottom: 8px;'>{{item.group}}</h3>
          <a style='height: 40px; padding-right: 0px; font-size: 13px' *ngFor="let option of item.items" mat-list-item
            (keyup.enter)="TratarItemMenu(option.url, option.cdNavegacao); sidenav.toggle()"
            (click)="TratarItemMenu(option.url, option.cdNavegacao); sidenav.toggle()">
            <mat-icon>{{option.icon}}</mat-icon>
            {{option.label}}
          </a>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sidenaveContent">
      <ngx-spinner bdOpacity=0.9 bdColor="#333" size="small" color="#f24c4c" type="ball-8bits" [fullScreen]="true">
        <p style="color: primary"> Aguarde... </p>
      </ngx-spinner>
      <router-outlet></router-outlet>
      <div class="toolbar___sem_borda" style="height: 60px;">
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
