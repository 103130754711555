import { Routes } from '@angular/router';

import { enumTiposUsuario } from './enums/enumTiposUsuario';
import { AdminGuardService } from './services/adminguard.service';

const _tpUsuario: number = localStorage.getItem('passeioja:tpUsuario') ? Number(localStorage.getItem('passeioja:tpUsuario')) : 0;

// loadComponent: () =>   indica lazy load apenas de um componente
// loadChildren: () =>    indica lazy load da arvore
export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: `${_tpUsuario === 0 ? '/Institucional' : '/AmbosGeral/Home'}`,
    pathMatch: 'full'
  },
  {
    path: 'Institucional',
    loadComponent: () => import('./pages/institucional/homeinstitucional/homeinstitucional.component').then(p => p.HomeInstitucionalComponent),
    canActivate: [AdminGuardService],
    data: { roles: ['livre'] }
  },
  {
    path: 'login',
    redirectTo: '/AmbosGeral/Login',
    pathMatch: 'full'
  },
  {
    path: 'AdminCad',
    loadChildren: () => import('./admin-cad.routes').then(r => r.ADMIN_CAD_ROUTES)
  },
  {
    path: 'AdminGeren',
    loadChildren: () => import('./admin-geren.routes').then(r => r.ADMIN_GEREN_ROUTES)
  },
  {
    path: 'AmbosCad',
    loadChildren: () => import('./ambos-cad.routes').then(r => r.AMBOS_CAD_ROUTES)
  },
  {
    path: 'AmbosGeral',
    loadChildren: () => import('./ambos-geral.routes').then(r => r.AMBOS_GERAL_ROUTES)
  },
  {
    path: 'AmbosGeren',
    loadChildren: () => import('./ambos-geren.routes').then(r => r.AMBOS_GEREN_ROUTES)
  },


];
