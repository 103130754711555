import { bootstrapApplication, Meta, Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app/app.component';
import { provideHttpClient } from '@angular/common/http';
import { ErrorHandler, isDevMode, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideServiceWorker, SwRegistrationOptions } from '@angular/service-worker';
import { provideNgxMask } from 'ngx-mask';

import { APP_ROUTES } from './app/app.routes';
import { GlobalErrorHandler } from './app/providers/globalErrorHandler';

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(APP_ROUTES),
    provideHttpClient(),
    provideAnimationsAsync(),
    provideNgxMask(),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },   // interceptar problema com o service-worker
    { provide: LOCALE_ID, useValue: 'pt-BR' }, // necessário para usar pipe currency do angular
    Title,
    Meta,
    DatePipe,
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // essas linhas abaixo foram copiadas do APP versao antiga - talvez nao precise
    {
      provide: SwRegistrationOptions,       // para o service-worker (obter atualização automatica de versão)
      useFactory: () => ({ enabled: !isDevMode() }),
    },

  ]
})
  .catch((err) => console.error(err));
