import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgFor, NgIf, NgStyle, registerLocaleData } from '@angular/common';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Subscription } from 'rxjs';

// LOCALE abaixo eh para funcionar o pipe currency do proprio angular
// vide tb provide: LOCALE_ID
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';

import { environment } from '../environments/environment';
import { IMenu, IMenuItem } from './interfaces/IMenu';

import { UsuarioModel } from './models/usuarioModel';
import { GuiaTurismoModel } from './models/guiaTurismoModel';
import { enumTiposUsuario } from './enums/enumTiposUsuario';
import { ComissionadoModel } from './models/comissionadoModel';

import { AdminGuardService } from './services/adminguard.service';
import { ScreensizeService } from './services/screensize.service';
import { SwupdaterService } from './services/swupdater.service';
import { UsuarioService } from './services/usuario.service';

registerLocaleData(localePt)

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, RouterModule,
    MatToolbarModule, MatIconModule, MatSidenavModule, MatMenuModule, MatListModule, MatButtonModule,
    NgIf, NgFor, NgStyle,
    NgxSpinnerModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  public isLogged: boolean = false;
  menu: Array<IMenu> = new Array<IMenu>();
  subscriptions: Array<Subscription> = [];
  _usu: UsuarioModel = new UsuarioModel();
  _guia: GuiaTurismoModel = new GuiaTurismoModel();
  _url: string = environment.url_api
  _nmFantasiaAgencia: string = '';
  _nmUsuario: string = '';
  _arrayMenuAgencia: IMenuItem[] = [];
  _arrayMenuPerfil: IMenuItem[] = [];
  _arrayMenuAjuda: IMenuItem[] = [];
  _tpUsuario: number = null;
  _flGuiaAdministrador: boolean = false;
  _flInstitucional: boolean = true;     // flag indicando que está na parte institucional - não inclui o cabeçalho do geren
  _urlPasseioJa: string = environment.urlPasseioJa;
  isDesktop: boolean;

  constructor(
    private adminGuardSrv: AdminGuardService,
    private screensizeSrv: ScreensizeService,
    public swupdaterSrv: SwupdaterService,
    private usuarioSrv: UsuarioService,

    private router: Router,
  ) {
    this.screensizeSrv.onResize(window.innerWidth);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => { sub.unsubscribe(); });
  }

  ngOnInit() {
    // Verifica se existe nova versão pelo Service Worker
    this.swupdaterSrv.checkForUpdates();
    this.subscriptions.push(this.screensizeSrv.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
      // console.log('isDesktop', isDesktop);
    }));
    this.subscriptions.push(this.adminGuardSrv.observableFlagInstitucional().subscribe(flInstitucional => {
      this._flInstitucional = flInstitucional;
      // console.log('flInstitucional', flInstitucional);
    }));
    this.isLogged = this.usuarioSrv.isStaticLogged;
    if (!this.isLogged) {
      this.usuarioSrv.carregarMenuInstitucional();
      this.menu = this.usuarioSrv.menu;
    }
    this.subscriptions.push(this.usuarioSrv.isLogged.subscribe(logged => {
      // console.log('logged', logged);
      this.isLogged = logged;
      if (logged) {
        this.CarregarDados();
      } else {
        this._tpUsuario = 0;
        this._nmFantasiaAgencia = '';
        this._nmUsuario = '';
        this._guia = new GuiaTurismoModel();
        this._usu = new UsuarioModel();
        this.menu.length = 0;
        setTimeout((a: any) => {
          this.usuarioSrv.carregarMenuInstitucional();
          this.menu = this.usuarioSrv.menu;
        }, 100, []);
        this._arrayMenuAgencia.length = 0;
        this._arrayMenuPerfil.length = 0;
      }
    }));
    this.CarregarDados();
  }

  // rotina para forçar a avaliação do tamanho da tela quando o usuário mudar o layout
  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.screensizeSrv.onResize(event.target.innerWidth);
  }

  // recurso para conseguir usar enum no html
  public get enumTiposUsuario() {
    return enumTiposUsuario;
  }

  CarregarDados() {
    if (localStorage.getItem('passeioja:tpUsuario')) {
      this._tpUsuario = Number(localStorage.getItem('passeioja:tpUsuario'));
    }
    if (localStorage.getItem('passeioja:flGuiaAdministrador')) {
      const _aux = localStorage.getItem('passeioja:flGuiaAdministrador');
      if (_aux == 'true') {
        this._flGuiaAdministrador = true;
      } else {
        this._flGuiaAdministrador = false;
      }
    }
    if (localStorage.getItem('passeioja:Usuario')) {
      this._usu = JSON.parse(localStorage.getItem('passeioja:Usuario')) as UsuarioModel;
      // Monta nome do usuario para página apenas com primeiro e segundo nome
      let _arrayPedaco = this._usu.nmUsuario.split(' ');
      this._nmUsuario = _arrayPedaco[0];
      if (_arrayPedaco.length > 1) {
        this._nmUsuario = this._nmUsuario + ' ' + _arrayPedaco[1];
      }
      if (this._usu.tpUsuario == enumTiposUsuario.Guia_1
        && localStorage.getItem('passeioja:GuiaTurismo')) {
        this._guia = JSON.parse(localStorage.getItem('passeioja:GuiaTurismo')) as GuiaTurismoModel;
        // Monta nome da agência para página apenas com primeiro e segundo nome
        _arrayPedaco = [];
        if (this._guia.agenciaTurismo) {
          _arrayPedaco = this._guia.agenciaTurismo.nmFantasiaAgencia.split(' ');
          this._nmFantasiaAgencia = _arrayPedaco[0];
          if (_arrayPedaco.length > 1) {
            this._nmFantasiaAgencia = this._nmFantasiaAgencia + ' ' + _arrayPedaco[1];
          }
        }
      }
    }
    if (!this._tpUsuario) {
      setTimeout((a: any) => {
        if (this.menu.length == 0) {
          this.usuarioSrv.carregarMenuSair();
          this.menu = this.usuarioSrv.menu;
        }
      }, 100, []);
    }
    let _comissionado = null;
    if (localStorage.getItem('passeioja:Comissionado')) {
      _comissionado = JSON.parse(localStorage.getItem('passeioja:Comissionado')) as ComissionadoModel;
    }
    if (!this.usuarioSrv.menu[0] && this._tpUsuario && this._usu) {
      this.usuarioSrv.carregarMenu(this._tpUsuario, this._usu, this._flGuiaAdministrador, _comissionado);
    }
    setTimeout((a: any) => {
      this.menu = this.usuarioSrv.menu;
      this._arrayMenuAgencia = this.usuarioSrv.menuAgencia;
      // console.log(this._arrayMenuAgencia)
      this._arrayMenuPerfil = this.usuarioSrv.menuPerfil;
      this._arrayMenuAjuda = this.usuarioSrv.menuAjuda;
    }, 100, []);
  }

  AbrirOutraAba(link: string) {
    window.open(link, '_blank');
  }

  NavegarHome() {
    if (this._flInstitucional) {
      location.hash = 'home';
      // this.router.navigateByUrl(`Institucional`);
    } else {
      this.router.navigateByUrl(`/AmbosGeral/Home`);
    }
  }

  ChamarRotaPrincipal(rota: string) {
    this.router.navigateByUrl(`${rota}`);
  }

  PosicionarTag(tag: string) {
    if (this.router.url.includes('Institucional')) {
      if (this._flInstitucional) {
        location.hash = tag;
      }
    } else {
      this.router.navigateByUrl(`Institucional`);
    }
  }

  TratarItemMenu(url: string, cdNavegacao: string) {
    if (cdNavegacao && cdNavegacao == 'Tag') {
      this.PosicionarTag(url);
    } else {
      if (cdNavegacao && cdNavegacao == 'Ext') {
        this.AbrirOutraAba(url);
      } else {
        this.ChamarRotaPrincipal(url);
      }
    }
  }

}
